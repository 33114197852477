import { Link } from 'react-router-dom';
import Logo from '../assests/logo_&_banner/BLACK.webp';






const NavLink = () => {

  return (
    <>

      <div className='section'>


        <div className='logo'>
          <a href='https://hkdesigngroup.in/'><img className="logoimg" src={Logo} style={{ width: "15%", }} alt='' /></a>
        </div>

        <div id="sidebarMenu">
          <ul className='nav-ul'>
            <li><Link to="https://hkdesigngroup.in/about-us/" className='link'>About</Link></li>
            <li><Link to="https://hkdesigngroup.in/services/" className='link'>Services</Link></li>
            <li><Link to="https://hkdesigngroup.in/shop/" className='link'>Shop</Link></li>
            <li><Link to="https://hkdesigngroup.in/our-work/" className='link'>Our work</Link></li>
            <li><Link to="https://hkdesigngroup.in/contact-us/" className='link'><button id='BTN'>CONTACT US</button></Link></li>
          </ul>
        </div>
      </div>


      {/* >>>>>>>>>>>>>>>>>>>>>>>phone nav<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

      <div className='phone-nav'>
        <div className='phone-logo'>
          <img className="logoimg" src={Logo} style={{ width: "20%", }} alt='' ></img>
         
        </div>

        <input type='checkbox' id='openSidebarMenu'></input>
        <label for="openSidebarMenu" class="slidebarIconToggle"> 
        
        <i id='mon' class="fa-solid fa-bars fa-2x" ></i>
        
        </label>
        <div id='phone-nav' >

          <ul id='phone-ul' class="mt-4">
            {/* <img src={cross} id="cross"></img> */}
            <li class="p-2 text-center"> <Link to="#" className='link'>About</Link></li>
            <li class="p-2 text-center"><Link to="#" className='link'>Services</Link></li>
            <li class="p-2 text-center"><Link to="#" className='link'>Shop</Link></li>
            <li class="p-2 text-center"><Link to="#" className='link'>Our work</Link></li>
            <li class="p-2 text-center"><Link to="#" className='link'><button  id='BTN'>CONTACT US</button></Link></li>
          </ul>
        </div>

      </div>
    </>
  )
}
export default NavLink;



