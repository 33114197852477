

// import React, { } from 'react';

import one from '../assests/NEw Imges hk/NISHU CHAWLA.webp';
import two from '../assests/NEw Imges hk/BUILDER FLOOR.webp';
import three from '../assests/NEw Imges hk/CAFE.webp';
import four from '../assests/NEw Imges hk/PETROL PUMP(2).webp';
import five from '../assests/NEw Imges hk/GYM.webp';
import six from '../assests/NEw Imges hk/UNWIND.webp';
import seven from '../assests/NEw Imges hk/ACHIN JAIN ARCHITECTURE.webp';
import eight from '../assests/NEw Imges hk/ACHIN JAIN INTERIOR.webp';
import nine from '../assests/NEw Imges hk/BALAJI HOSPITAL.webp';
import ten from '../assests/NEw Imges hk/BESTECH GRAND SPA.webp';
import eleven from '../assests/NEw Imges hk/BMG ELEGANT CITY.webp';
import twelve from '../assests/NEw Imges hk/CA OFFICE(1).webp';
import thirteen from '../assests/NEw Imges hk/GOLDEN VILLAS.webp';
import fourteen from '../assests/NEw Imges hk/HONDA LANDSCAPE.webp';
import fifteen from '../assests/NEw Imges hk/KARTIK ARCHITECTURE.webp';
import sixteen from '../assests/NEw Imges hk/KATYAL HOSPITAL.webp';
import seventeen from '../assests/NEw Imges hk/KARTIK INTERIOR.webp';
import eighteen from '../assests/NEw Imges hk/00MASTER PLANNING.webp';
import nineteen from '../assests/NEw Imges hk/RAJ KUMAR YADAV GURUTEK - ARCHITECTURE.webp';
import twenty from '../assests/NEw Imges hk/RAJ KUMAR YADAV GURUTEK - INTERIOR.webp';
import twentyone from '../assests/NEw Imges hk/SMART KIDS SCHOOL.webp';
import twentytwo from '../assests/NEw Imges hk/SUDHIR ARORA.webp';
import twentythree from '../assests/NEw Imges hk/secotr-3.webp'
import twentyfour from '../assests/NEw Imges hk/BESTECH SANSKRUTI.webp';


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";







const Blog = () => {

  return (
    <>




      <TransformWrapper initialScale={1.8}
        initialPositionX={-150}
        initialPositionY={-270}
        smooth={true}
        minScale={1}
        maxScale={2.5}
      // minPositionX={-2500}	
      // maxPositionX	={500}
      // minPositionY	={-120}
      // maxPositionY={500}
      >
        <TransformComponent>


          <div className="horizontal" >






            {/*ONE*/}
            <div className='row'>
              <div className='col w-75'>
                <div className="vertical " id='one'>
                  <a href="https://hkdesigngroup.in/malibu-town/"><img src={one} alt="NIshu Chawla" className="zoom-image" /></a>
                  <h2>APARTMENT AT MALIBU TOWN</h2>
                </div>


              </div>

              <div className='col'>
                <div className="vertical" id='two'>
                  <a href="https://hkdesigngroup.in/redwood-floors/"><img src={two} alt="BUILDER FLOOR AT REDWOOD CITY" className="zoom-image" /></a>
               <div><h2>BUILDER FLOOR AT REDWOOD CITY</h2></div>
                </div>
              </div>



              <div className='col'>
                <div className="vertical" id='three'>
                  <a href="https://hkdesigngroup.in/zaika-cafe/"> <img src={three} alt="ZAIKA CAFE" className="zoom-image" /></a>
                  <h2>ZAIKA CAFE</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='four'>
                  <a href="https://hkdesigngroup.in/patrol-pump/"> <img src={four} alt="HP PETROL PUMP<" className="zoom-image" /></a>
                  <h2>HP PETROL PUMP</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='five'>
                  <a href="https://hkdesigngroup.in/gym/"> <img src={five} alt="Gym" className="zoom-image" /></a>
                  <h2>TEAM R4 FITNESS STUDIO & GYM</h2>
                </div>
              </div>
            </div>


            {/*two*/}
            <div className='row'>
              <div className='col'>
                <div className="vertical" id='six'>
                  <a href="https://hkdesigngroup.in/unwind-pain-management/"> <img src={six} alt="Unwind" className="zoom-image" /></a>
                  <h2>UNWIND PAIN MANAGEMENT & OSTEOPATHIC CENTER</h2>

                </div>
              </div>

              <div className='col' >
                <div className="vertical" id='seven'>
                  <a href="https://hkdesigngroup.in/malibu-town/"> <img src={seven} alt="NIshu Chawla" className="zoom-image" /></a>

                  <h2>NISHU CHAWLA</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='eight'>
                  <a href="https://hkdesigngroup.in/sector-3/"> <img src={eight} alt="RESIDENCE AT SECTOR 3" className="zoom-image" /></a>
                  <h2>RESIDENCE AT SECTOR 3</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='nine'>
                  <a href="https://hkdesigngroup.in/bajaji-host-resi/"> <img src={nine} alt="BALAJI HOSPITAL & RESIDENCE" className="zoom-image" /></a>
                  <h2>BALAJI HOSPITAL & RESIDENCE</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='ten'>
                  <a href="https://hkdesigngroup.in/apartment-at-bestech-grand-spa/"> <img src={ten} alt="GRAND SPA" className="zoom-image" /></a>

                  <h2> APARTMENT AT BESTECH GRAND SPA</h2>
                </div>
              </div>
            </div>


            {/*three*/}
            <div className='row'>
              <div className='col'>
                <div className="vertical" id='eleven'>
                  <a href="https://hkdesigngroup.in/bmg-elegant-city/"> <img src={eleven} alt="BMG EI EGANT CITY" className="zoom-image" /></a>
                  <h2>PESIDENDE AT BMG EI EGANT CITY</h2>
                </div>
              </div>

              <div className='col'>
                <div className="vertical" id='twelve'>
                  <a href="https://hkdesigngroup.in/office-for-ca-aggarwal/"> <img src={twelve} alt="CA AGGARWAL" className="zoom-image" /></a>
                  <h2>OFFICE FOR CA AGGARWAL</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='thirteen'>
                  <a href="https://hkdesigngroup.in/golden-villas/"> <img src={thirteen} alt="GOLDEN VILLAS" className="zoom-image" /></a>
                  <h2>RESIDENCE AT GOLDEN VILLAS</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='fourteen'>
                  <a href="https://hkdesigngroup.in/honda-landscape/"> <img src={fourteen} alt="honda-landscape" className="zoom-image" /></a>
                  <h2>LANDSCAPE AREA AT HONDA TWO-WHEELERS</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='fifteen'>
                  <a href="https://hkdesigngroup.in/residence-at-rosewood-city/"> <img src={fifteen} alt="RESIDENCE AT ROSEWOOD CITY" className="zoom-image" /></a>
                  <h2>RESIDENCE AT ROSEWOOD CITY</h2>
                </div>
              </div>
            </div>



            {/*four*/}
            <div className='row'>
              <div className='col'>
                <div className="vertical" id='sixteen'>
                  <a href="https://hkdesigngroup.in/kalyal-hospital/"> <img src={sixteen} alt="KATYAL HOSPITAL" className="zoom-image" /></a>
                  <h2>KATYAL HOSPITAL</h2>
                </div>
              </div>

              <div className='col'>
                <div className="vertical" id='seventeen'>
                  <a href="https://hkdesigngroup.in/rosewood-city-2/"> <img src={seventeen} alt="RESIDENCE AT ROSEWOOD CITY" className="zoom-image" /></a>
                  <h2>RESIDENCE AT ROSEWOOD CITY</h2>

                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='eighteen'>
                  <a href="https://hkdesigngroup.in/master-planning/"> <img src={eighteen} alt="Master plan" className="zoom-image" /></a>
                  <h2>CONCEPTUAL MATER PLANNING</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='nineteen'>
                  <a href="https://hkdesigngroup.in/residence-at-gurutek/"> <img src={nineteen} alt="NIshu Chawla" className="zoom-image" /></a>
                  <h2>RESIDENCE AT GURUTEK</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='twenty'>
                  <a href="https://hkdesigngroup.in/residence-at-gurutek-city/"> <img src={twenty} alt="NIshu Chawla" className="zoom-image" /></a>
                  <h2>RESIDENCE AT GURUTEK CITY</h2>
                </div>
              </div>
            </div>



            {/*five*/}
            <div className='row'>
              <div className='col'>
                <div className="vertical" id='twentyone'>
                  <a href="https://hkdesigngroup.in/smart-kid-school/"> <img src={twentyone} alt="SMART KID SCHOOL" className="zoom-image" /></a>
                  <h2>SMART KID SCHOOL</h2>
                </div>
              </div>

              <div className='col'>
                <div className="vertical" id='twentytwo'>
                  <a href="https://hkdesigngroup.in/residence-at-eden-garden/"> <img src={twentytwo} alt="residence-at-eden-garden" className="zoom-image" /></a>
                  <h2>RESIDENCE AT EDEN GARDEN</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='twentythree'>
                  <a href="https://hkdesigngroup.in/sector3-2/"> <img src={twentythree} alt="sector3-2" className="zoom-image" /></a>
                  <h2>RESIDENCE AT SECTOR-3(2)</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='twentyfour'>
                  <a href="https://hkdesigngroup.in/bestech-sanskruti/"> <img src={twentyfour} alt="RESIDENCE AT BESTECH SANSKRUTI" className="zoom-image" /></a>
                  <h2>RESIDENCE AT BESTECH SANSKRUTI</h2>
                </div>
              </div>
              <div className='col'>
                <div className="vertical" id='twentyfive'>
                  <a href="https://hkdesigngroup.in/malibu-town/"> <img src={one} alt="NIshu Chawla" className="zoom-image" /></a>
                  <h2>RESIDENCE AT MALIBU TOWN</h2>
                </div>
              </div>
            </div>


          </div>
        </TransformComponent>
      </TransformWrapper>



    </>
  )
}

export default Blog;






