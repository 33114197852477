
import './App.css';

import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Blog from './components/Blogs';
import NavLink from './components/NavLink';

function App() { 
  return (
    <div className="App">
      <BrowserRouter>
        <NavLink />
        <Routes>
          <Route path='/' element={<Blog/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
